<!-- 商城 => 规格弹窗 -->
<template>
    <div class="drawer">
        <div class="shade" @click="evClearAlert"></div>
        <div class="drawer_content">
            <span class="iconfont icon-close-icon" @click="evClearAlert"></span>
            <!-- 机型名称 -->
            <span class="drawer_model">{{ oModelName }}</span>
            <!-- 版本 -->
            <div class="drawer_title">选择版本</div>
            <div class="specification">
                <div class="specification_tag">
                    <el-tag
                        :type="oMemoryLight === i ? 'warning' : 'info'"
                        :effect="oMemoryLight === i ? 'plain' : 'dark'"
                        v-for="(item, i) in oMemory"
                        @click="evToggleTag(item, i)"
                        size="small"
                        :key="i"
                    >{{ item.specs_name }}</el-tag>
                </div>
            </div>
            <!-- 颜色 -->
            <div class="drawer_title">颜色</div>
            <div class="specification">
                <div class="specification_tag">
                    <el-tag
                        :type="oColorLight === index ? 'warning' : 'info'"
                        :effect="oColorLight === index ? 'plain' : 'dark'"
                        :class="[color.stock === 0 ? 'dis_tag' : '']"
                        v-for="(color, index) in oColors"
                        @click="evToggleColor(color, index)"
                        size="small"
                        :key="index"
                    >
                        {{ color.mobile_color }}
                    </el-tag>
                </div>
            </div>
            <!-- 价格/台数 -->
            <div class="footer">
                <div class="total">
                    <!-- 单价: <span>{{ oMemoryMoney || '--' }}</span> -->
                    <div>库存: {{ oStock || 0 }}</div>
                </div>
                <el-button
                    :class="[oStock ? '' : 'gray_scale']"
                    @click="evOperate('add')"
                    v-if="!oSelected"
                    type="primary"
                    size="small"
                >
                    <span class="iconfont icon-jiahao1"></span>
                    加入购物车
                </el-button>
                <input-number v-else @operate="evOperate">{{ oSelected }}</input-number>
            </div>
        </div>
    </div>
</template>

<script>
// 接口
import { GetSpeceList } from '@/api'
// 组件
import InputNumber from './InputNumber.vue'

export default {
    props: {
        // 订单 id
        whiteId: {
            type: String
        }
    },
    data () {
        return {
            // 库存
            oStock: 0,
            // 颜色列表
            oColors: [],
            // 颜色高亮
            oColorLight: 0,
            // 内存高亮
            oMemoryLight: 0,
            // 内存规格列表
            oMemory: [],
            // 机型名称
            oModelName: '',
            // 规格价格
            oMemoryMoney: null,
            // 当前机型详情
            oNowModel: {},
            // 选择规格数量
            oMemoryNum: null,
            // 已选规格颜色数量
            oSelected: null,
            // 品牌 Id
            oBrandId: null
        }
    },
    methods: {
        /**
         * 规格选择 (接口)
         *  1. 父组件调用 传值(item: { id: 机型 id, model_name: 机型名称, number: 总选择机型数量, memory_info: 规格详情, brand_id: 品牌 id })
         *  2. 结构赋值
         *  3. 判断 '总选择机型数量' 是否存在
         *      1. 有: 将'规格详情' 回显相应内容。(所有规格、第一台价格、第一台选择的数量、颜色列表、第一台规格第一个颜色已选择的数量)
         *      2. 无: 请求 '规格列表' 接口, 参数(model_id: 机型 id)
         *          1. 赋值(规格、第一台规格价格、颜色列表)
         */
        apiSpeceList: function (item) {
            const { id, model_name, number, memory_info, brand_id } = item
            Object.assign(this, {
                'oNowModel': item,
                'oBrandId': brand_id,
                'oModelName': model_name
            })
            if (number) {
                Object.assign(this, {
                    'oMemory': memory_info,
                    'oMemoryMoney': memory_info[0].guidance_price,
                    'oMemoryNum': memory_info[0].memory_num,
                    'oColors': memory_info[0].specs,
                    'oSelected': memory_info[0].specs[0]?.select_num,
                    'oStock': memory_info[0].specs[0]?.stock
                })
                return
            }
            GetSpeceList({ model_id: id, white_id: this.whiteId })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        data.map(item => {
                            if (!item.specs.length) {
                                item.specs = [
                                    {
                                        'id': 0,
                                        'stock': 99,
                                        'mobile_color': '随机'
                                    }
                                ]
                            }
                        })
                        Object.assign(this, {
                            'oMemory': data,
                            'oColors': data[0]?.specs,
                            'oStock': data[0]?.specs[0].stock,
                            'oMemoryMoney': data[0]?.guidance_price
                        })
                        return
                    }
                    this.$message.error(msg)
                })
        },
        /**
         * 加入购物车、子组件(加/减) 操作
         *  参数 type: add/minus
         *  1. 获取当前规格台数
         *      ps:(每个机型刚弹出规格框时, 是没有 memory_num 字段的。现在获取是因为再次进入规格弹框时, 好做累加工作)
         *  2. 赋值(oN: 当前规格台数, oModelNum: 当前机型总台数, oCn:当前颜色选中终端数量)
         *  3. type: add/minus, add: 加一台, minus: 减一台
         *      加: 判断当前库存是否为0, 当前规格台数 + 1, 机型总台数 + 1, 颜色台数 + 1
         *      减: 同上反向操作
         *  4. 赋值: oMemoryNum = 当前规格台数, oNowModel.number = 机型总台数, oSelected = 已选中颜色台数
         *      ps: (为了给弹窗赋值, 也方便给父组件传值)
         *  5. 将当前机型规格写入父组件机型内。
         *      'memory_num': 当前规格台数,
         *      'status': (加/减) 操作,
         *      'brand_id': 品牌 id
         *      'model_id': 机型 id,
         *      'model_name': 机型名称
         *  5.1. 将当前颜色台数写入规格内的 specs 内
         *  5.2. 将当前库存写入源 obj 内
         *  6.调用父组件 '补差款金额计算' 方法
         */
        evOperate: function (type) {
            const { oMemory, oMemoryLight, oColorLight, oNowModel, oBrandId } = this
            const { memory_num, specs } = oMemory[oMemoryLight]
            let oCn = specs[oColorLight]?.select_num || 0
            let oN = memory_num || 0
            let oModelNum = oNowModel.number || 0
            if (type === 'add') {
                if (this.oStock <= 0) return
                oN++
                oCn++
                oModelNum++
                this.oStock--
            } else {
                oN--
                oCn--
                oModelNum--
                this.oStock++
            }
            this.oMemoryNum = oN
            this.oSelected = oCn
            this.oNowModel.number = oModelNum
            Object.assign(this.oMemory[oMemoryLight], {
                'memory_num': oN,
                'status': type,
                'brand_id': oBrandId,
                'model_id': oNowModel.id,
                'model_name': oNowModel.model_name
            })
            this.oMemory[oMemoryLight].specs[oColorLight].select_num = oCn
            this.oMemory[oMemoryLight].specs[oColorLight].stock = this.oStock
            this.$emit('evJoinShoppingCart', oMemory[oMemoryLight])
        },
        /**
         * 规格切换
         *  1. 参数(obj:规格详情, index: 下标)
         *  2. 赋值(下标高亮、规格价格、颜色列表)
         *  3. 循环判断，那个有库存，优先选择有库存的那个
         *      3.1、调用颜色切换方法
         *  4. 当所有颜色都没有库存时, 默认使用第一个颜色库存。
         */
         evToggleTag: function (obj, index) {
            Object.assign(this, {
                'oMemoryLight': index,
                'oMemoryMoney': obj.guidance_price,
                'oColors': obj.specs
            })
            const noStock = obj.specs.some((el, i) => {
                if (el.stock) {
                    this.evToggleColor(obj.specs[i], i)
                }
                return el.stock
            })
            if (!noStock) {
                this.evToggleColor(obj.specs[0], 0)
            }
        },
        /**
         * 颜色切换
         */
         evToggleColor: function (item = {}, i) {
            Object.assign(this, {
                'oColorLight': i,
                'oStock': item.stock,
                'oSelected': item.select_num
            })
         },
        /**
         * 取消弹框
         *  1. 赋值 (下标高亮清零、规格台数清零、颜色下标高亮清零、已选规格颜色数量清零、颜色列表清空)
         *  2. 机型详情内加入 品牌 id (ps: 为防止未选择规格数量, 直接点击退出弹框, 父组件又需要 品牌 id)
         *  3. 调用父组件方法 (整合父组件传过来的参数, 然后返给父组件)
         */
         evClearAlert () {
            Object.assign(this, {
                'oMemoryLight': 0,
                'oColorLight': 0,
                'oMemoryNum': null,
                'oSelected': null,
                'oColors': []
            })
            if (this.oMemory.length) {
                Object.assign(this.oMemory[this.oMemoryLight], {
                    'brand_id': this.oBrandId,
                    'model_id': this.oNowModel.id
                })
            }
            this.$emit('evCloseAlert', this.oMemory)
        }
    },
    components: {
        InputNumber
    }
}
</script>

<style lang="scss" scoped>
.drawer {
    position: absolute;
    top: 0;
    z-index: 10;

    .shade {
        content: '';
        display: block;
        overflow: hidden;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, .3);
    }

    &_content {
        position: absolute;
        top: 200px;
        left: 55px;
        padding: 40px 24px;
        width: 640px;
        height: 900px;
        background: #fff;
        border-radius: 24px;
        text-align: center;

        .icon-close-icon {
            position: absolute;
            top: -66px;
            right: 10px;
            font-size: 50px;
            color: #fff;
        }

        .drawer_model {
            display: block;
            margin-bottom: 56px;
        }

        .drawer_title {
            font-size: 26px;
            color: #999;
            text-align: left;

            &::before {
                display: inline-block;
                content: '';
                margin-right: 14px;
                width: 8px;
                height: 26px;
                background: #F2980E;
                border-radius: 0 8px 8px 0;
                overflow: hidden;
                vertical-align: middle;
            }
        }

        .specification {
            min-height: 10vw;
            overflow-y: scroll;

            &_tag {
                padding: 15px 0;
                text-align: left;

                .el-tag {
                    margin: 15px 20px;
                }
                .dis_tag {
                    color: #b7b7b7;
                    background: #f7f7f7;
                }
            }
        }

        .footer {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-between;
            padding: 20px 0 40px;
            width: calc(100% - 48px);
            border-top: 1px solid #ddd;

            .total {
                font-size: 28px;
                line-height: 60px;

                span {
                    font-size: 38px;
                    color: #FF2A00;

                    &::before {
                        content: '￥';
                        display: inline;
                        overflow: hidden;
                        font-size: 28px;
                    }
                }
            }

            .el-button {
                border: 0;
                border-radius: 100px;
                background: linear-gradient(90deg, #F2970C, #FEC56E);
                &.gray_scale {
                    filter: grayscale(1);
                }
            }
        }
    }
}
</style>

<style lang="scss">
.el-tag {
    text-align: center;

    &.el-tag--dark.el-tag--info {
        color: #666;
        border: 0;
        background: #F0F0F0;
    }
}
</style>
