<template>
    <div class="anew">
        <!-- 检索 -->
        <div class="search">
            <div class="search_model">
                <el-input placeholder="请输入您想要搜索的机型" prefix-icon="el-icon-search" size="small" v-model="input2"></el-input>
                <span class="title">搜索</span>
            </div>
            <div class="net_age">
                <div class="search_title">选择期数</div>
                <div class="net_age_tag">
                    <el-tag
                        :effect="oTagLight === item.value ? 'plain' : 'dark'"
                        :type="oTagLight === item.value ? 'warning' : 'info'"
                        v-for="(item, index) in tagList"
                        @click="evToggleTag(item.value)"
                        :key="index"
                        size="small"
                    >{{ item.name }}</el-tag>
                </div>
            </div>
            <div class="electronic_coupons">
                <div class="search_title">最高电子券金额:
                    <div class="ele_bark_fooder" @click="evCustomMoney" v-if="isCustomMoney">
                        <span class="search_title_money">{{ oRepayMoney }}</span>
                        <span class="search_old_money" v-if="oldRepayMoney != oRepayMoney">{{ oldRepayMoney }}</span>
                        <span class="iconfont icon-dqht-icon"></span>
                    </div>
                    <div class="ele_bark_fooder" v-else>
                        <el-input placeholder="请输入电子券金额" v-model.trim="oCustomMoney" maxlength="10" clearable />
                        <el-button type="primary" size="small" @click="evConfirmCustom">确定</el-button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 商城主体 -->
        <div class="shopping">
            <!-- 商城品牌栏 -->
            <div class="shopping_brand">
                <el-badge
                    :hidden="item.model_sel_num === 0"
                    v-for="(item, i) in brandList"
                    :value="item.model_sel_num"
                    :key="i"
                >
                    <div :class="['shopping_brand_item', oBrandLight === item.id ? 'brand_light' : '']" @click="fnRecommend(item)">{{ item.brand_name }}</div>
                </el-badge>
            </div>
            <!-- 商品机型栏 -->
            <div class="shopping_model">
                <div v-for="(item, index) in modelList" :key="index" :class="[oBrandLight !== '1' ? 'inline' : '']">
                    <el-badge :value="item.number" class="item" :hidden="item.number === 0" v-if="oBrandLight !== '1'">
                        <el-tag
                            :effect="item.number ? 'plain' : 'dark'"
                            @click="evGoToModelAlert(item, index)"
                            :type="item.number ? 'warning' : 'info'"
                            size="small"
                        >
                            {{ item.model_name }}
                        </el-tag>
                    </el-badge>
                    <div v-else>
                        <el-divider content-position="left">{{ item.titile }}</el-divider>
                        <div v-for="(mod, i) in item.list" :key="i" class="badge">
                            <el-tag
                                :effect="oIn === mod.id ? 'plain' : 'dark'"
                                @click="evSetTagPrefecture(mod)"
                                :type="oIn === mod.id ? 'warning' : 'info'"
                                size="small"
                            >
                                {{ mod.model_name }}
                            </el-tag>
                        </div>
                    </div>
                </div>
                <!-- 无数据展示 -->
                <section v-show="modelList.length === 0 && islock">
                    <empty :EmptyMsg="oEmptyHint" />
                </section>
            </div>
        </div>
        <!-- 结算金额 -->
        <div class="money">
            <div :class="[oShoppingPay > 0 ? 'poor' : '', 'money_suspension']">
                <div class="money_city_icon" @click="evIsShowShowDrawer">
                    <el-badge :value="oModelNum" class="item" :max="99" :hidden="oModelNum === 0">
                        <span class="iconfont icon-gwc02"></span>
                    </el-badge>
				</div>
                <div class="down_payment" @click="evIsShowShowDrawer">
                    <!-- 补差款<span>￥ {{ downPayment }}</span> -->
                    <div class="now_repay">{{ `当前电子券金额 ${ oCalculusRepayMoney }` }}</div>
                </div>
                <el-button class="money_btn btn_query" @click="evGoToMachineInfo" :disabled="oShoppingPay <= 0" type="warning">确定</el-button>
            </div>
        </div>
        <!-- 规格弹框 -->
        <transition name="fade">
            <cus-drawer
                ref="drawer"
                v-show="isClose"
                :whiteId="oWhiteId"
                @evCloseAlert="fnCloseAlert"
                @evJoinShoppingCart="fnJoinShoppingCart"
            />
        </transition>
        <!-- 购物车弹框 -->
        <transition name="fade">
            <cus-shop-cart
                ref="shop_cart"
                :oMoArr="oMoArr"
                v-show="isShopCart"
                v-model:isShow="isShopCart"
                @fnInstantRecovery="evRecovery"
                @evDelMoItem="fnDelMoItem"
                @evJoinShoppingCart="fnJoinShoppingCart"
            />
        </transition>
    </div>
</template>

<script>
// 接口
import { GetGoodsChoice, GetBrandList, GetRules } from '@/api'
// 组件
import CusShopCart from './components/ShoppingCart.vue'
import CusDrawer from './components/Drawer.vue'
import Empty from '@/views/empty.vue'

export default {
    data () {
        return {
            // 省份
            l_province: localStorage.getItem('province'),
            // 拷贝品牌
            oCopyBrand: [],
            // 福建合伙人专区 tag 索引
            oIn: null,
            // 空品牌提示语
            oEmptyHint: '该品牌暂无可选机型',
            // 锁
            islock: false,
            // 是否展示购物车
            isShopCart: false,
            input2: '',
            // 是否展示自定义金额
            isCustomMoney: true,
            // 自定义电子金额
            oCustomMoney: null,
            // 旧电子券金额
            oldRepayMoney: null,
            // 电子券
            oRepayMoney: '',
            // 逆推电子券金额
            oCalculusRepayMoney: 0,
            // 客户 id
            oWhiteId: null,
            // 弹窗
            isClose: false,
            // tag 高亮
            oTagLight: '24',
            // 品牌高亮
            oBrandLight: 0,
            // 购物车金额
            oShoppingPay: 0,
            // 品牌列表
            brandList: [],
            // 对应品牌型号列表
            modelList: [],
            // 推荐已选择
            oElect: {},
            // 点击内存位置
            oModelIndex: null,
            // 补差款
            oPayment: 0,
            // 已选机型数量
			oModelNum: 0,
            // 已选机型
            oModelObj: {},
            // 购物车展示已选机型
            oMoArr: [],
            // 基数
            cardinal: null,
            // 期数 tag
            tagList: [
                { name: '12期', value: '12' },
                { name: '24期', value: '24' },
                { name: '30期', value: '30' },
                { name: '36期', value: '36' }
            ],
            // 利率
            oInterestRate: {
                '12': 1.12,
                '24': 1.20,
                '30': 1.26,
                '36': 1.28
            }
        }
    },
    methods: {
        // 终端推荐列表 (接口)
        apiGoodsChoice: function (brand_item) {
            const { oTagLight: net_age, oWhiteId: white_id, oRepayMoney: repay_money } = this
            const { referenceCode } = this.$store.getters.orderItem
            switch (this.l_province) {
                case '1168':
                    if (referenceCode) {
                        this.fnRecommend(brand_item)
                        Object.assign(this, {
                            'islock': true,
                            'oCopyBrand': JSON.parse(JSON.stringify(this.brandList))
                        })
                        return
                    }
                    break
            }
            GetGoodsChoice({ net_age, white_id, repay_money, province: this.l_province })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        if (data.length !== 0) {
                            this.brandList.unshift({
                                id: 0,
                                brand_name: '推荐',
                                model: data
                            })
                            this.oBrandLight = 0
                            this.modelList = this.fnFilterElect(data)
                        } else {
                            this.fnRecommend(brand_item)
                        }
                        return
                    }
                    this.$message.error(msg)
                })
                .finally(() => {
                    this.islock = true
                    this.oCopyBrand = JSON.parse(JSON.stringify(this.brandList))
                })
        },
        // 各省规则 (接口)
        apiRules: async function () {
            const { l_province: province } = this
            if (!province) {
                await this.$store.dispatch('user/getInfo')
                this.l_province = localStorage.getItem('province')
            }
            GetRules({ province })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        // console.log('【该省规则】', data)
                        this.cardinal = parseFloat(data.rules)
                        return
                    }
                    this.$message.error(msg)
                })
        },
        /**
         * 切换 tag 时, 推荐赋值 (data: 推荐接口数据)
         *  注释(oElect: 已选推荐机型)
         *  1. 如果已选推荐机型小于等于0, 返回 推荐接口数据
         *  2. 否则就遍历已选推荐机型
         *      循环接口数据, 判断遍历的id 与 推荐机型 id 是否相同
         *          相同: 遍历 id 赋值为当前 item
         */
        fnFilterElect: function (data) {
            const oE = this.oElect
            if (oE.length <= 0) return data
            for (const k in oE) {
                for (let i = 0, l = data.length; i < l; i++) {
                    if (data[i].id === oE[k].id) {
                        data[i] = JSON.parse(JSON.stringify(oE[k]))
                    }
                }
            }
            // oElect.map((item) => {
            //     for (let i = 0, l = data.length; i < l; i++) {
            //         if (data[i].id === item.id) {
            //             data[i] = JSON.parse(JSON.stringify(item))
            //         }
            //     }
            // })
            return data
        },
        // 品牌列表 (接口)
        apiBrandList: function () {
            const oMachineHistory = sessionStorage.getItem('machine')
            const oModalHistory = sessionStorage.getItem('o_m_obj')
            const oElectHistory = sessionStorage.getItem('elect')
            const oModelNum = sessionStorage.getItem('model_num')
            const oCar = sessionStorage.getItem('cardinal')
            if (oMachineHistory) {
                Object.assign(this, {
                    'oShoppingPay': Number(sessionStorage.getItem('shopping_pay')),
                    'oRepayMoney': sessionStorage.getItem('repay_money'),
                    'oTagLight': sessionStorage.getItem('shopping_tag'),
                    'brandList': JSON.parse(oMachineHistory),
                    'oCopyBrand': JSON.parse(oMachineHistory),
                    'oModelObj': JSON.parse(oModalHistory),
                    'oIn': sessionStorage.getItem('o_in'),
                    'oElect': JSON.parse(oElectHistory),
                    'cardinal': oCar,
                    'islock': true,
					oModelNum
                })
                this.fnRecommend(this.brandList[0])
                return
            }
            GetBrandList({ white_id: this.oWhiteId })
                .then(({ code, msg, data }) => {
                    if (code === 1000) {
                        // console.log('[品牌]', data)
                        Object.assign(this, {
                            'brandList': data
                        })
                        // 终端推荐列表 (方法)
                        this.apiGoodsChoice(data[0])
                        // 各省规则 (方法)
                        this.apiRules()
                        return
                    }
                    this.$message.error(msg)
                })
        },
        // 展示相应品牌型号
        fnRecommend: function (item) {
            const { id, model } = item
            Object.assign(this, {
                'modelList': model,
                'oBrandLight': id
            })
        },
        // 规格弹框展示
        evGoToModelAlert: function (item, i) {
            Object.assign(this, {
                'isClose': true,
                'oModelIndex': i
            })
            this.$refs['drawer'].apiSpeceList(item)
            if (this.oIn) {
                this.oModelObj = {}
                this.oRepayMoney = this.oldRepayMoney
                this.oShoppingPay = null
                this.oIn = null
            }
        },
        // 合伙人专区
        evSetTagPrefecture: function (item, i) {
            Object.assign(this, {
                'oRepayMoney': item.repay_money,
                'oShoppingPay': item.guidance_price,
                'oTagLight': '24',
                'brandList': JSON.parse(JSON.stringify(this.oCopyBrand)),
                'oIn': item.id
            })
            this.oModelObj = {
                0: {
                    'brand_name': item.model_name,
                    'model_name': item.model_name,
                    'memory_num': 1,
                    'specs_name': '',
                    'id': item.id,
                    'specs': [
                        {
                            'mobile_color': '随机',
                            'select_num': 1,
                            'id': '0'
                        }
                    ]
                    // brand_id: 10,
                    // model_id: 1075,
                    // guidance_price: '1299.00',
                    // retail: '1299.00',
                    // status: 'add'
                }
            }
        },
        // 补差款金额计算
        fnJoinShoppingCart: function (obj) {
            const { status, guidance_price, brand_id } = obj
            this.brandList.map((brand, key) => {
                if (brand.id === brand_id) {
                    brand.model_sel_num = brand.model_sel_num || 0
                    if (status === 'add') {
                        brand.model_sel_num++
                        this.oModelNum++
                        this.oShoppingPay += parseFloat(guidance_price)
                    } else {
                        brand.model_sel_num--
                        this.oModelNum--
                        this.oShoppingPay -= parseFloat(guidance_price)
                    }
                    this.oModelObj[obj.id] = JSON.parse(JSON.stringify(
                        Object.assign(obj, { brand_name: brand.brand_name })
                    ))
                    // console.log('[品牌]', this.oShoppingPay)
                }
            })
        },
        // 规格弹窗关闭
        fnCloseAlert: function (memory_info) {
            // console.log('[内存]', memory_info)
            if (memory_info.length === 0) {
                this.isClose = false
                return
            }
            const { brand_id, model_id } = memory_info.find((item) => item.brand_id)
            let oNum = 0
            // memory_info.map(val => (oNum += val.memory_num || 0))
            memory_info.map(val => {
                oNum += val.memory_num || 0
            })
            this.modelList[this.oModelIndex].memory_info = memory_info
            this.isClose = false
            if (this.oBrandLight === 0) {
                for (const brand of this.brandList) {
                    if (brand.id === brand_id) {
                        brand.model.map((model) => {
                            if (model.id === model_id) {
                                model.number = oNum
                                model.memory_info = memory_info
                            }
                        })
                        break
                    }
                }
            } else {
                if (this.brandList[0].brand_name === '推荐') {
                    for (const k of this.brandList[0].model) {
                        if (k.id === model_id) {
                            Object.assign(k, {
                                brand_id,
                                memory_info,
                                'number': oNum
                            })
                        }
                    }
                }
            }
        },
        // 结算 (按钮)
        evGoToMachineInfo: function () {
            const { oInterestRate, oTagLight, oShoppingPay, oRepayMoney, brandList, oWhiteId, oCalculusRepayMoney, oModelObj, oElect, cardinal, oIn, oModelNum } = this
            const oRate = oInterestRate[oTagLight]
            const oPay = oShoppingPay
            const rental = (oPay - (oRepayMoney * oTagLight / oRate)).toFixed(2)
            const payment = oPay === 0 ? '--' : rental >= 0 ? rental : 0
            sessionStorage.setItem('machine', JSON.stringify(brandList))
            sessionStorage.setItem('o_m_obj', JSON.stringify(oModelObj))
            sessionStorage.setItem('elect', JSON.stringify(oElect))
            sessionStorage.setItem('shopping_pay', oShoppingPay)
            sessionStorage.setItem('repay_money', oRepayMoney)
            sessionStorage.setItem('shopping_tag', oTagLight)
            sessionStorage.setItem('model_num', oModelNum)
            sessionStorage.setItem('cardinal', cardinal)
            sessionStorage.setItem('o_in', oIn)
            this.$router.push({
                'name': 'CusMachineInfo',
                'query': { payment, combo: '基础套餐', id: oWhiteId, tag: oTagLight, repay_money: oCalculusRepayMoney }
            })
        },
        /**
         * 切换期数 (type: 期数)
         *  注释(oTagLight: tag高亮)
         *  0. 判断 合伙人是否选中，选中只能选择24期
         *  1. 判断高亮是否与期数相同, 相同直接返回。不执行任何操作
         *  2. tag 高亮赋值
         *  3. 调用筛选推荐 (方法)
         */
        evToggleTag: function (type) {
            if (this.oIn) { return }
            if (this.oTagLight === type) return
            this.oTagLight = type
            this.fnGetGoods()
        },
        /**
         * 筛选推荐 (方法)
         * 注释(,brandList: 品牌列表, oElect: 推荐已选择的数据)
         * 1. 判断品牌id是否是 0; 0代表推荐
         *      获取品牌列表第一条数据遍历, 符合条件筛选出来赋值给 oElect
         *      作用: (oElect)
         */
        fnGetGoods: function () {
            const oList = this.brandList
            if (oList[0]?.id === 0) {
                const { model } = oList.shift()
                model.filter(model => {
                    if (model.number > 0) {
                        this.oElect[model.id] = model
                    }
                })
            }
            this.apiGoodsChoice(oList[0])
            this.fnBackwards()
        },
        /**
         * 购物车展示
         * 备注: oModelObj: 已选机型对象, isShopCart: 购物车弹框展示状态, oShoppingPay: 购物车金额
         *  1. 判断购物车内是否有内容, 没有不可点击
         *  2. 购物车数组清空, 防止再次点击重复 push
         *  3. for 循环, 对象转数组. 判断循环对象个数是否不为0, 为0 跳过 push
         *  4. 调用子组件防范, 传入 brandList: 品牌列表, 用来更改品牌数量
         *  5. 更改 isShopCart 状态
         */
        evIsShowShowDrawer: function () {
            const { oModelObj, isShopCart, oShoppingPay } = this
            if (oShoppingPay <= 0) return
            this.oMoArr = []
            for (const i in oModelObj) {
                if (oModelObj[i].memory_num > 0) {
                    this.oMoArr.push(oModelObj[i])
                }
            }
            if (!isShopCart) this.$refs['shop_cart'].evGetBrandList(this.brandList)
            this.isShopCart = !isShopCart
            // console.log('[购物车]', this.oMoArr)
        },
        /**
         * 当前机型归 0 时, 删除该机型
         *  1. 删除该机型
         *  2. 当购物车内机型为空时, 关闭购物车弹框
         */
        fnDelMoItem: function (i) {
            this.oMoArr.splice(i, 1)
            if (this.oMoArr.length <= 0) {
                this.isShopCart = false
            }
        },
        /**
         * 一键清空 (购物车子组件调用)
         *  1. 清空所有临时缓存, 防止调用品牌接口有缓存不调用接口
         *  2. 一下参数全部清空
         *      oShoppingPay: 购物车总金额
         *      oTagLight: tag 高亮
         *      brandList: 品牌列表
         *      oModelObj: 已选机型
         *      islock: 锁
         *      isShopCart: 购物车展示
         *  3. 调用品牌接口, 就不用重新赋值 brandList 了
         */
        evRecovery: function () {
            sessionStorage.clear()
            Object.assign(this, {
                'oShoppingPay': 0,
                'oModelNum': 0,
                'oTagLight': '24',
                'brandList': [],
                'oModelObj': {},
                'islock': true,
                'isShopCart': false
            })
            this.apiBrandList()
        },
        // 逆推电子券金额
        fnBackwards: function () {
            const { oInterestRate: oR, oTagLight: net_age, oShoppingPay: pay, oRepayMoney: repay } = this
            const m = this.cardinal
			const mu = 100
            const t = Math.ceil((pay * oR[net_age] * mu / net_age).toFixed(2))
            const r = t % (m * mu)
            const d = r ? (t - r + m * mu) / mu : t / mu
            this.oCalculusRepayMoney = repay <= (pay * oR[net_age]) / net_age
                ? repay
                : d > repay ? repay : d
            // const oCeil = Math.ceil(((pay * oRate) / net_age) / 10) * 10
            // this.oCalculusRepayMoney = repay <= (pay * oRate) / net_age
            //     ? repay
            //     : oCeil > repay ? repay : oCeil
        },
        // 确定自定义金额
        evConfirmCustom: function () {
            const reg = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/
            const { oCustomMoney, cardinal, oldRepayMoney } = this
            if (oCustomMoney) {
                const rmb = parseFloat(oCustomMoney)
                let om = ''
                const redFruits = [
                    { condition: rmb, hint: '请正确填写金额' },
                    { condition: reg.test(rmb), hint: '金额格式错误' },
                    { condition: rmb <= oldRepayMoney, hint: '不能大于最高电子券金额' },
                    { condition: !(parseInt(rmb * 100) % parseInt(cardinal * 100)), hint: `根据联通省份规定电子券必须是${cardinal}的倍数` }
                ]
                redFruits.some(item => {
                    if (!item.condition) {
                        om = item.hint
                        return true
                    }
                })
                if (om) return this.$message.error(om)
                this.oRepayMoney = oCustomMoney
                this.fnGetGoods()
            }
            this.evCustomMoney()
        },
        // 自定义电子券金额
        evCustomMoney: function () {
            this.isCustomMoney = !this.isCustomMoney
        }
    },
    watch: {
        // 倒推电子券
        oShoppingPay (now, old) {
            this.fnBackwards()
        }
    },
    computed: {
        /**
         * 补差款计算
         *  补差款公式: 购物车金额 - (电子券 * 期数) / (1 + 利率)
         *  备注: 购物车金额 < 补差款时 不退钱, 所以展示0
         *       购物车金额 > 补差款时 计算差价
         *       保留两位小数, 四舍五入
         */
        downPayment () {
            const oRate = this.oInterestRate[this.oTagLight]
            const oPay = this.oShoppingPay
            const rental = (oPay - (this.oRepayMoney * this.oTagLight / oRate)).toFixed(2)
            return oPay === 0 ? '--' : rental >= 0 ? rental : 0
        }
    },
    mounted () {
        // 电子券
        const { repay_money, id } = this.$route.query
        const { tagList } = this
        Object.assign(this, {
            'oldRepayMoney': parseFloat(repay_money),
            'oRepayMoney': repay_money,
            'oWhiteId': id
        })
        // 品牌列表 (方法)
        this.apiBrandList()
        switch (this.l_province) {
            case '1168':
                this.tagList = [tagList[1], tagList[3]]
                break
            case '2367':
                this.tagList = [tagList[1], tagList[3]]
                break
        }
    },
    components: {
        CusShopCart,
        CusDrawer,
        Empty
    }
}
</script>

<style lang="scss" scoped>
$abacus_h: 100px;
.anew {
    display: flex;
    flex-flow: column;
    height: 100vh;
    background: #FAFAFA;

    // 检索
    .search {
        margin: 24px 18px 0;
        border-bottom: 1px solid #E6E6E6;

        &_model {
            margin-bottom: 30px;

            .title {
                margin-left: 18px;
                font-size: 28px;
                color: #2A2A2A;
            }
        }

        &_title {
            font-size: 26px;
            color: #999;

            &::before {
                display: inline-block;
                content: '';
                margin-right: 14px;
                width: 8px;
                height: 26px;
                background: #F2980E;
                border-radius: 0 8px 8px 0;
                overflow: hidden;
                vertical-align: middle;
            }

            &_money {
                color: #333;
            }
        }

        .net_age {
            &_tag {
                margin: 20px 0 0 20px;
                display: flex;
                // justify-content: space-between;

                .el-tag {
                    margin-right: 36px;
                    width: 144px;
                }
            }
        }

        .electronic_coupons {
            margin: 36px 0 20px;
            line-height: 60px;
        }
    }

    // 商城主体
    .shopping {
        flex: 1;
        display: flex;
        justify-content: space-between;
        font-size: 26px;
        overflow-y: scroll;

        &_brand {
            padding-bottom: 180px;
            width: 160px;
            background: #F0F0F0;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                display: none;
            }

            .el-badge {
                display: block;
                width: 100%;

                .shopping_brand_item {
                    height: 76px;
                    line-height: 76px;
                    text-align: center;
                    color: #666;
                    border-bottom: 1px solid rgb(209, 208, 208);

                    &.brand_light {
                        color: #000;
                        background: #fff;
                    }
                }
            }
        }

        &_model {
            flex: 1;
            padding: 36px 20px 180px;
            overflow-y: scroll;
            background: #fff;
            &_item {
                top: 14px;
            }
            .inline {
                display: inline;
            }
            .el-badge, .badge {
                margin: 0 40px 30px 0;
            }
        }
    }

    // 结算金额
    .money {
        position: absolute;
		bottom: 0;
        height: 164px;
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.6);
        z-index: 10;
        &_suspension {
            position: relative;
			display: flex;
			justify-content: space-between;
			margin: 0 auto;
			width: 690px;
			height: $abacus_h;
			color: #fff;
			border-radius: 100px;
			overflow: hidden;
			background: #2F3233;
			z-index: 19;
            .money_city_icon {
				position: relative;
				.iconfont {
					margin-left: 40px;
					line-height: $abacus_h;
					font-size: 70px;
					color: #9D9D9D;
				}
			}
            .down_payment {
                flex: 1;
                padding: 12px 24px;
                font-size: 28px;

                .now_repay {
                    line-height: 76px;
                    color: #fff;
                    font-size: 18px;
                }
            }
            .btn_query {
                // background: #F2980E;
                border-radius: 0;
            }

            &_btn {
                width: 200px;
                // line-height: 100px;
                text-align: center;
                font-size: 32px;
                color: #fff;
                border-radius: 0;
            }
            &.poor {
                background: #142B46;
                .iconfont {
                    color: #F2980E;
                }
            }
        }
    }
}
</style>

<style lang="scss">
.anew {
    .search_model {
        .el-input {
            width: 630px;
            background: #F0F0F0;
            border-radius: 100px;

            .el-input__inner {
                background: transparent;
                border: 0;

                &::placeholder {
                    color: #919191;
                }
            }
        }
    }
    .search_title {
        .ele_bark_fooder {
            display: inline-block;
            margin-left: 10px;

            .search_old_money {
                margin-left: 10px;
                color: red;
                font-size: 22px;
                text-decoration: line-through;
            }

            .el-input {
                width: 380px;
                &__inner {
                    height: 50px;
                }
            }

            .el-button {
                padding: 0 20px;
                min-height: 50px;
                font-size: 20px;
                color: #F2980E;
                background: transparent;
                border: 0;
            }

            .iconfont {
                font-size: 26px;
                color: #F2980E;
            }
        }
    }
}

.el-tag {
    text-align: center;
    font-size: 20px;
    display: inline-block;

    &.el-tag--plain.el-tag--warning {
        color: #F2980F;
        border-color: #F2980F;
        background: #FFFBF2;
    }

    &.el-tag--dark.el-tag--info {
        color: #666;
        border: 0;
        background: #F0F0F0;
    }

    &.el-tag--small {
        height: 54px;
        line-height: 54px;
    }
}

.shopping_brand {
    .el-badge {
        .el-badge__content.is-fixed {
            transform: translateY(0%) translateX(50%);
        }
    }
}
.money_city_icon {
    .el-badge {
        .el-badge__content.is-fixed {
            top: 24px;
        }
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
