<!-- 商城 => 规格弹框 => 加减组件 -->
<template>
    <div class="input">
        <span
            class="iconfont icon-a-jianhaozhankaishanchu1"
            @click="evOperateNumber('minus')"
            ontouchstart=""
            onmouseover=""
        />
        <slot></slot>
        <span
            class="iconfont icon-a-jiahaoshouqitianjia1"
            @click="evOperateNumber('add')"
            ontouchstart=""
            onmouseover=""
        />
    </div>
</template>

<script>
export default {
    methods: {
        // 加/减操作 type: add加、minus减
        evOperateNumber: function (type) {
            this.$emit('operate', type)
        }
    }
}
</script>

<style lang="scss" scoped>
$icon-size: 36px;
$icon-color: #F2980E;

.input {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 160px;

    span {
        color: #666;
        font-size: $icon-size;
        line-height: $icon-size;

        &:hover{
            color: #666;
        }

        &:active{
            color: $icon-color;
            transform: translateY(4px)
        }
    }
}
</style>
