<template>
    <div class="shop">
        <!-- 遮罩 -->
        <div class="shade" @click="evClearShoppingCart"></div>
        <!-- 购物车 -->
        <div class="cart">
            <div class="cart_title">
                <span class="cart_title_text">{{ `已选终端(共${ oCount }件商品)`}}</span>
                <span class="iconfont icon-shanchu" @click="clear()">一键清空</span>
            </div>
            <section class="cart_content">
                <div v-for="(item, index) in oMoArr" :key="index">
                    <div class="cart_list" v-for="(model, key) in item.specs" :key="key" :class="[model.select_num ? '' : 'dis_space']">
                        <div class="cart_list_brand" v-if="model.select_num">
                            <span class="brand">{{ `${ item.model_name }`}}</span>
                            <span class="memory">{{ `规格: ${ item.specs_name } 颜色: ${ model.mobile_color }` }}</span>
                        </div>
                        <div v-if="model.select_num">
                            <input-number @operate="(type) => evOperate(type, item, index, model)">{{ model.select_num }}</input-number>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
// 组件
import InputNumber from './InputNumber.vue'

export default {
    props: {
        // 已选机型
        oMoArr: {
            type: Array,
            default: () => []
        },
        // 关闭弹窗
        isShow: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            // 父组件品牌列表
            oBrands: [],
            // 产品计数
            oCount: 0
        }
    },
    methods: {
        // 一键清空 (按钮)
        clear: function () {
            this.$emit('fnInstantRecovery')
        },
        // 父组件调用, 获取父端 品牌列表
        evGetBrandList: function (brands) {
            Object.assign(this, {
                'oBrands': brands,
                'oCount': 0
            })
            brands.map(brand => {
                this.oCount += brand.model_sel_num || 0
            })
        },
        /**
         * 机器加减 (加减子组件调用)
         * @param {*} type 加减
         * @param {*} item 规格
         * @param {*} index 下标
         *  1.
         */
        evOperate: function (type, item, index, model) {
            const { brand_id, model_id, id } = item
            let { memory_num } = item
            this.oCount = type === 'add' ? ++this.oCount : --this.oCount
            Object.assign(item, {
                'memory_num': type === 'add' ? ++memory_num : --memory_num,
                'status': type
            })
            model.select_num = type === 'add' ? ++model.select_num : --model.select_num
            for (let k = 0, b = this.oBrands; k < b.length; k++) {
                if (b[k].id === brand_id || b[k].id === 0) {
                    for (let m = 0, oML = b[k].model; m < oML.length; m++) {
                        const { id: m_id, memory_info } = oML[m]
                        let { number } = oML[m]
                        if (m_id === model_id) {
                            oML[m].number = type === 'add' ? ++number : --number
                            for (let g = 0, o = memory_info; g < o.length; g++) {
                                if (o[g].id === id) {
                                    o[g] = item
                                    break
                                }
                            }
                            break
                        }
                    }
                }
            }
            if (memory_num <= 0) this.$emit('evDelMoItem', index)
            this.$emit('evJoinShoppingCart', item)
        },
        // 关闭购物车
        evClearShoppingCart: function () {
            this.$emit('update:isShow', false)
        }
    },
    components: {
        InputNumber
    }
}
</script>

<style lang="scss" scoped>
.shop {
    .shade {
        content: '';
        display: block;
        overflow: hidden;
        position: fixed;
        top: 0;
        height: 100vh;
        width: 100vw;
        background: rgba(0, 0, 0, .3);
        z-index: 1;
    }

    .cart {
        position: absolute;
        bottom: 0;
        background: #fff;
        width: 100%;
        height: 60vh;
        border-radius: 25px;
        overflow: hidden;
        z-index: 2;

        &_title {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            line-height: 90px;
            border: 1px solid #F2F2F2;

            &_text {
                font-size: 26px;

                &::before {
                    display: inline-block;
                    content: '';
                    margin-right: 14px;
                    width: 8px;
                    height: 26px;
                    background: #F2980E;
                    border-radius: 0 8px 8px 0;
                    overflow: hidden;
                    vertical-align: middle;
                }
            }

            .iconfont {
                font-size: 24px;
                color: #999;

                &::before {
                    margin-right: 10px;
                    font-size: 30px;
                    vertical-align: middle;
                }
            }
        }

        &_content {
            padding-bottom: 100px;
            height: calc(100% - 138px);
            overflow-y: scroll;

            .cart_list {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0 24px;
                padding: 24px 0;
                border-bottom: 1px solid #F2F2F2;

                &.dis_space {
                    padding: 0;
                    border-bottom: none;
                }

                &_brand {
                    span {
                        display: block;
                        width: 500px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .brand {
                        line-height: 56px;
                        color: #333;
                        font-size: 32px;
                    }

                    .memory {
                        color: #999;
                        font-size: 26px;
                    }
                }
            }
        }
    }
}
</style>
